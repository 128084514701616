////////////////////////////////////////////////
// DARKLY
////////////////////////////////////////////////
$grey-lighter: #dbdee0;
$grey-light: #8c9b9d;
$grey: darken($grey-light, 18);
$grey-dark: darken($grey, 18);
$grey-darker: darken($grey, 23);

$orange: #e67e22;
$yellow: #f1b70e;
$green: #2ecc71;
$turquoise: #1abc9c;
$blue: #3498db;
$purple: #8e44ad;
$red: #e74c3c;
$white-ter: #ecf0f1;
$primary: #375a7f !default;
$yellow-invert: #fff;

$family-sans-serif: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI",
  "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-monospace: "Inconsolata", "Consolas", "Monaco", monospace;

$radius-small: 3px;
$radius: 0.4em;
$radius-large: 8px;
$size-6: 16px;
$size-7: 0.875em;
$title-weight: 500;
$subtitle-weight: 400;

$border-width: 2px;
$border: $grey;

$body-background-color: darken($grey-darker, 4);
$body-size: 1rem;

$background: $grey-darker;
$footer-background-color: $background;
$button-background-color: $background;
$button-border-color: lighten($button-background-color, 15);

$title-color: #fff;
$subtitle-color: $grey-lighter;
$subtitle-strong-color: $grey-lighter;

$text: #fff;
$text-light: lighten($text, 10);
$text-strong: darken($text, 5);

$box-color: $text;
$box-background-color: rgba($grey-dark, 0.7);
$box-shadow: none;

$link: $turquoise;
$link-hover: lighten($link, 5);
$link-focus: darken($link, 5);
$link-active: darken($link, 5);
$link-focus-border: $grey-light;

$button-color: $primary;
$button-hover-color: darken($text, 5); // text-dark
$button-focus: darken($text, 5); // text-dark
$button-active-color: darken($text, 5); // text-dark
$button-disabled-background-color: $grey-light;

$control-height: 2.5em;

$input-color: $grey-darker;
$input-icon-color: $grey;
$input-icon-active-color: $input-color;
$input-hover-color: $grey-light;
$input-disabled-background-color: $grey-light;
$input-disabled-border: $grey-lighter;

$table-color: $text;
$table-head: $grey-lighter;
$table-background-color: $grey-dark;
$table-cell-border: 1px solid $grey;

$table-row-hover-background-color: $grey-darker;
$table-striped-row-even-background-color: $grey-darker;
$table-striped-row-even-hover-background-color: lighten($grey-darker, 2);

$pagination-color: $link;
$pagination-border-color: $border;

$navbar-height: 4rem;

$navbar-background-color: $primary;
$navbar-item-color: $text;
$navbar-item-hover-color: $link;
$navbar-item-hover-background-color: transparent;
$navbar-item-active-color: $link;
$navbar-dropdown-arrow: #fff;
$navbar-divider-background-color: rgba(0, 0, 0, 0.2);
$navbar-dropdown-border-top: 1px solid $navbar-divider-background-color;
$navbar-dropdown-background-color: $primary;
$navbar-dropdown-item-hover-color: $grey-lighter;
$navbar-dropdown-item-hover-background-color: transparent;
$navbar-dropdown-item-active-background-color: transparent;
$navbar-dropdown-item-active-color: $link;

$dropdown-content-background-color: $background;
$dropdown-item-color: $text;

$progress-value-background-color: $grey-lighter;

$bulmaswatch-import-font: true !default;

$file-cta-background-color: $grey-darker;

$progress-bar-background-color: $grey-dark;

$panel-heading-background-color: $grey-dark;
