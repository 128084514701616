@charset "utf-8";
@import url('https://fonts.googleapis.com/css2?family=Bangers:wght@400&display=swap');

@import "./themes/darkly_variables";
@import "bulma";
@import "bulma-switch";
@import "bulma-timeline";
// @import "bulma-o-steps";
@import "./themes/darkly_custom";

// CUSTOM
.has-border-bottom {
  border-bottom: 1px solid $border;
}

.mb-npx {
  margin-bottom: -1px;
}

.is-order-1 {
  order: 1;
}

.is-order-2 {
  order: 2;
}

.is-order-3 {
  order: 3;
}

#mainNavbar {
  padding-bottom: 5rem;
}

@include tablet {
  #mainNavbar {
    padding-bottom: 0;
  }
}

@include desktop {
  .navbar-scrolled-down {
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
  }

  .navbar-scrolled-up {
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.20);
  }

  .is-order-1-desktop {
    order: 1;
  }

  .is-order-2-desktop {
    order: 2;
  }

  .is-order-3-desktop {
    order: 3;
  }
}

.switch[type=checkbox]:focus + label:before,
.switch[type=checkbox]:focus + label:after {
    outline: 0;
}

.gradient-border {
  border: 4px solid $primary;
}

/* common */
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}

.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #3498db;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 sans-serif;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
  z-index: 1;
}

/* top right*/
.ribbon-top-right {
  top: -10px;
  right: -10px;
}

.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}

.ribbon-top-right::before {
  top: 0;
  left: 0;
}

.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}

.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}

.is-line-through {
  text-decoration: line-through;
}

#bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
}

.neon-success {
  text-shadow:
    0 0 7px $success,
    0 0 10px $success,
    0 0 21px $success,
    0 0 42px #888,
    0 0 82px #888,
    0 0 92px #888,
    0 0 102px #888,
    0 0 151px #888;
}

.neon-warning {
  text-shadow:
    0 0 7px $warning,
    0 0 10px $warning,
    0 0 21px $warning,
    0 0 42px #888,
    0 0 82px #888,
    0 0 92px #888,
    0 0 102px #888,
    0 0 151px #888;
}

.important-box {
  box-shadow: rgba(241, 183, 14, .5) 0px 0px 0px 1px, rgba(241, 183, 14 / 40%, .5) 0px 0px 4px 8px;
}

.brand-title {
  font-family: 'Bangers', sans-serif;
}

.tags.is-fullwidth {
  width: 100%;
}

.tag.is-one-third {
  width: 33.3%;
}

.tag.is-two-thirds {
  width: 66.6%;
}
